import React, { useEffect, useState } from "react";
import ArtItem from "./art-item";
import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

interface ArtProps {
	toggleModal: (activeImgInput: string) => void;
}

function Art(props: ArtProps) {
	const [artData, setArtData] = useState<any[]>([]);

	useEffect(() => {
		async function fetchData() {
			const artCollection = collection(db, "items");
			const artQuery = query(artCollection);
			const artQuerySnapshot = await getDocs(artQuery);
			const data = artQuerySnapshot.docs.map((doc) => doc.data());
			setArtData(data);
		}

		fetchData();
	}, []);

	return (
		<div id="paintings">
			<h1 className="w3-row-padding w3-center">Malerier</h1>
			<p className="w3-large w3-center w3-margin-left w3-margin-right">
				Alle bildene er malt med akrylmaling fra Amsterdam.
			</p>
			<div className="w3-row-padding flex-container" id="art">
				{artData.map((item) => (
					<ArtItem
						src={item.src}
						size={item.size}
						alt={item.alt}
						price={item.price}
						description={item.description}
						sold={item.sold}
						bannerText={
							item.banner?.text !== undefined
								? item.banner.text
								: ""
						}
						bannerColor={
							item.banner?.color !== undefined
								? item.banner.color
								: ""
						}
						toggleModal={props.toggleModal}
					/>
				))}
			</div>
		</div>
	);
}

export default Art;
